<template>
  <v-container fluid>
    <v-row justify="center" align="center">
      <v-col sm="8" md="6" lg="6" xl="6" align-self="center">
        <v-card class="card">
          <v-toolbar
            color="headforms"
            height="30"
            elevation="0"
            dense>
            <v-spacer></v-spacer>
            <span class="mdlHeadForms">Restablecer contraseña</span>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-divider></v-divider>
          <v-card-text>
            <v-form>
              <v-col sm="10" md="10" lg="10" xl="10" class="contenedor">
                <span class="titleslight--text">Nueva contraseña</span>
                <span class="titleslight--text ml-1">*</span>
                <v-text-field 
                  v-model="password"
                  :type="(passVisible) ? 'text' : 'password'"
                  :rules="passwordValidaciones"
                  :append-icon="(passVisible) ? 'fa-eye' : 'fa-eye-slash'"
                  @click:append="passVisible = !passVisible"
                  outlined
                  dense
                  required>
                </v-text-field>
              </v-col>
              <v-col sm="10" md="10" lg="10" xl="10" class="contenedor">
                <span class="titleslight--text">Confirmar</span>
                <span class="titleslight--text ml-1">*</span>
                <v-text-field 
                  v-model="chkpassword"
                  :type="(confirmPassVisible) ? 'text' : 'password'"
                  :rules="ConfirmarcionPWD"
                  :append-icon="(confirmPassVisible) ? 'fa-eye' : 'fa-eye-slash'"
                  @click:append="confirmPassVisible = !confirmPassVisible"
                  outlined
                  dense
                  required>
                </v-text-field>
              </v-col>
              <small class="d-flex justify-end"><span class="titleslight--text">* Campo Obligatorio</span></small>
            </v-form>
          </v-card-text>
          <!-- <v-divider></v-divider> -->
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="font-weight-bold"
              color="btncolor"  
              @click="putPassword()"
              dark>
              Restablecer
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import  axiosServices  from '@/services/axiosServices.js';
var md5 = require('md5');
export default {
  name:"RestorePass",
  data() {
    return {
      password    : '',
      chkpassword : '',
      bValidate   : this.validateInputs(),
      passVisible : false,
      confirmPassVisible : false,
      ConfirmarcionPWD : [
        v => !!v || 'La confirmación es requerida',
        v => v==this.password || 'Las contraseñas no coinciden',
        v => v==this.chkpassword || 'Las contraseñas no coinciden'
      ],
      passwordValidaciones : [
        v => !!v || 'La clave es requerida',
        v => /^[a-zA-Z0-9!#-+/.*@·~%&¬()=?¿¡{}[\]]{5,}$/.test(v) || 'La contraseña contiene caracteres invalidos.'
      ],
    }
  },
  methods: {
    validateInputs() {
      this.bValidate = false;
      if (typeof this.$route.params.Hash !== "undefined") {
        if (this.password !== "" && this.chkpassword !== "" &&
          /^[a-zA-Z0-9!#-+/.*@·~%&¬()=?¿¡{}[\]]{5,}$/.test(this.password) &&
          this.password == this.chkpassword) {
          this.bValidate = true;
        }
      }
      return this.bValidate;
    },
    returnHome() {
      this.$router.replace({
        name: "login"
      });
    },
    putPassword() {
      if (this.validateInputs()) {
        let oBody = {
          "sNewPass": md5(this.password),
          "sChkNewPass": md5(this.chkpassword),
          "Hash": this.$route.params.Hash
        };
        axiosServices.putConfiguration('restore_password', oBody)
        .then(aResponseUpdate => {
          this.$store.dispatch('notificaciones/SetMensaje', `${aResponseUpdate.sMessage}`);
          this.$store.dispatch('notificaciones/SetColor','success')
          this.$store.dispatch('notificaciones/ShowNotificacion', true);
          this.password = '';
          this.chkpassword = '';
          setTimeout(function () {
            this.returnHome()
          }.bind(this), 3000);
        });
      } else {
        this.$store.dispatch('notificaciones/SetMensaje', "Uno de los campos se encuentra vacio ó las contraseñas no coinciden.");
        this.$store.dispatch('notificaciones/SetColor','error')
        this.$store.dispatch('notificaciones/ShowNotificacion', true);
      }
    },
  }
}
</script>